import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress} from '@mui/material';
import {FC, ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';

type ConfirmDialogProps = {
    /**
     * Whether is dialog open
     */
    readonly open: boolean;

    /**
     * Whether there is progress
     */
    readonly loading?: boolean;

    /**
     * Dialog title id
     */
    readonly title?: string;

    /**
     * Dialog description id or node
     */
    readonly description?: string | ReactNode;

    /**
     * "No" text for No button
     */
    readonly noButtonText?: string;

    /**
     * "Yes" text for Yes button
     */
    readonly yesButtonText?: string;

    /**
     * "No" handler
     */
    readonly onClickNo?: () => void;

    /**
     * "Yes" handler
     */
    readonly onClickYes?: () => void;
};

/**
 * Show confirm dialog
 */
export const ConfirmDialog: FC<ConfirmDialogProps> = ({open, loading, title, description, noButtonText, yesButtonText, onClickNo, onClickYes}) => {
    const handleOnClickYes = () => {
        if (onClickYes) {
            onClickYes();
        }
    };

    const handleOnClickNo = () => {
        if (onClickNo) {
            onClickNo();
        }
    };

    return (
        <Dialog open={open} keepMounted onClose={handleOnClickNo}>
            <DialogTitle>
                <FormattedMessage id={title ?? 'potvrzeni'} />
            </DialogTitle>
            <DialogContent>
                {loading && <LinearProgress />}
                {description && typeof description === 'string' ? (
                    <DialogContentText>
                        <FormattedMessage id={description} />
                    </DialogContentText>
                ) : (
                    description
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    sx={(theme) => ({
                        border: `solid 1px ${theme.palette.primary.main}`,
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.common.white,
                            border: `solid 1px ${theme.palette.secondary.main}`,
                        },
                    })}
                    size="small"
                    onClick={handleOnClickNo}
                    disabled={loading}
                    data-testid={`confirm-no-button`}
                >
                    <FormattedMessage id={noButtonText ? noButtonText : 'button.ne'} />
                </Button>
                <Button data-testid={`confirm-yes-button`} size="small" onClick={handleOnClickYes} variant="contained" color="primary" disabled={loading}>
                    <FormattedMessage id={yesButtonText ? yesButtonText : 'button.ano'} />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
