import {Button, Grid, Typography} from '@mui/material';
import {Store} from 'client/lib/store';
import {useRouter} from 'next/router';
import {FC, memo, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {useCommonAction} from '../../action/CommonAction';
import {PageRoute} from '../../constants';

import {areEqual, changePage} from '../../utils/CommonUtils';
import {ConfirmDialog} from '../dialogs/ConfirmDialog';

type Props = {
    readonly onSubmit?: () => Promise<void> | void;
    readonly error?: boolean;
    readonly disabled?: boolean;
    readonly hideButton?: boolean;
};

export const ButtonBase: FC<Props> = memo(({onSubmit, error, hideButton, disabled}) => {
    const [changeRoute, setChangeRoute] = useState(false);
    const {push, pathname, events} = useRouter();
    const {resetStore, setActiveStep} = useCommonAction();
    const {activeStep} = useSelector((state: Store) => state.common);
    const path = changePage(activeStep, 'left');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handleRouteChange = () => setChangeRoute((prevState) => !prevState);
        if (pathname !== PageRoute.VSTUP) {
            events.on('routeChangeStart', handleRouteChange);
        }

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            events.off('routeChangeStart', handleRouteChange);
        };
    }, [events, pathname]);

    const handleBack = () => {
        push({pathname: pathname === PageRoute.VSTUP ? PageRoute.VSTUP : path}).then(() => setActiveStep(activeStep - 1));
    };

    const handleNext = () => onSubmit?.();

    const nahdleStartReset = () => setOpen((prevState) => !prevState);

    const handleReset = () => {
        resetStore();
    };

    return (
        <Grid container spacing={2} sx={{marginTop: 5}}>
            <Grid
                item
                xs={12}
                sx={{
                    marginTop: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {!hideButton && (
                    <>
                        {activeStep !== 0 && (
                            <Button
                                variant="outlined"
                                disableRipple
                                sx={(theme) => ({
                                    color: theme.palette.error.main,
                                    border: `solid 1px ${theme.palette.error.main}`,
                                    marginRight: theme.spacing(3),
                                    width: 120,
                                    '&:hover': {
                                        backgroundColor: theme.palette.error.main,
                                        color: theme.palette.common.white,
                                    },
                                })}
                                onClick={nahdleStartReset}
                                disabled={changeRoute}
                            >
                                <FormattedMessage id="button.reset" />
                            </Button>
                        )}
                        <Button
                            disableRipple
                            variant="outlined"
                            sx={(theme) => ({
                                marginRight: theme.spacing(3),
                                width: 120,
                                border: `solid 1px ${theme.palette.primary.main}`,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                    color: theme.palette.common.white,
                                    border: `solid 1px ${theme.palette.secondary.main}`,
                                },
                            })}
                            onClick={handleBack}
                            disabled={changeRoute}
                        >
                            <FormattedMessage id="button.zpet" />
                        </Button>
                    </>
                )}

                <Button
                    disabled={error || disabled || changeRoute}
                    type="submit"
                    color="primary"
                    variant={error || disabled || changeRoute ? 'contained' : 'outlined'}
                    disableRipple
                    sx={
                        error
                            ? {
                                  width: 120,
                              }
                            : {
                                  width: 120,
                                  backgroundColor: 'primary.main',
                                  color: 'common.white',
                                  '&:hover': {
                                      backgroundColor: 'secondary.main',
                                      color: 'common.white',
                                  },
                              }
                    }
                    onClick={handleNext}
                >
                    <FormattedMessage id={activeStep === 3 && pathname === PageRoute.SOUHRN_ZADOSTI ? 'button.send' : 'button.next'} />
                </Button>
            </Grid>
            <ConfirmDialog
                open={open}
                onClickNo={nahdleStartReset}
                onClickYes={handleReset}
                description={
                    <Typography>
                        <FormattedMessage id="reset.confirm" />
                    </Typography>
                }
            />
        </Grid>
    );
}, areEqual);
