import {CloseOutlined} from '@mui/icons-material';
import {Box, IconButton, InputAdornment, Tooltip, Typography} from '@mui/material';
import {DateValidationError} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {endOfMonth, format, isSameDay} from 'date-fns';
import {FC, ReactNode, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Span} from '../../styledComponents/Span';
import {FIELD_WITH_ERROR_FLASH_CLASS} from '../helperComponents/ErrorFocus';
import {TooltipIcon} from '../helperComponents/TooltipIcon';
import {CalendarIcon} from '../icons/CalendarIcon';

type Props = {
    readonly label?: string;
    readonly name: string;
    readonly title?: string;
    readonly autoFocus?: boolean;
    readonly helperText?: string | undefined | null;
    readonly FormHelperTextProps?: any;
    readonly disabled?: boolean;
    readonly infinite?: boolean;
    readonly minDate?: Date;
    readonly maxDate?: Date;
    readonly onChange: (...event: any[]) => void;
    readonly onBlur: (...event: any[]) => void;
    readonly error: boolean;
    readonly required: boolean;
    readonly value: any;
    readonly disableFuture?: boolean;
    readonly disablePast?: boolean;
    readonly shouldDisableDate?: boolean;
    readonly tooltip?: ReactNode;
};

const renderFormLabel = (label: string | undefined, title: string, showRequired: boolean) => (
    <Span>
        {label}
        {showRequired ? <sup>*</sup> : false}
        {typeof title === 'string' && title.length > 0 && (
            <Tooltip title={title} placement="top">
                <sup>
                    &nbsp;&nbsp;
                    <FormattedMessage id="otaznik" />
                </sup>
            </Tooltip>
        )}
    </Span>
);

export const KeyboardDatePickerField: FC<Props> = ({
    onChange,
    onBlur,
    value,
    FormHelperTextProps,
    label,
    disabled,
    helperText,
    minDate,
    maxDate,
    error,
    required,
    name,
    disableFuture,
    tooltip,
    disablePast,
    shouldDisableDate,
}) => {
    const divRef = useRef(null);

    const [pickerOpen, setPickerOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null | undefined>(null);

    const helperTextId = helperText && name ? `${name}-helper-text` : undefined;

    const showRequired = required;

    const formattedLabel = label ?? undefined;

    const handleOpenPicker = () => {
        setAnchorEl(divRef?.current);
        setPickerOpen(true);
    };

    /**
     * Handler na změnu datumu
     * @param {any} dateVal
     * @param {string|null} currentValue
     */
    const handleOnChange = (dateVal: Date | null, {validationError}: {validationError: DateValidationError}) => {
        if (validationError === 'invalidDate') {
            onBlur('');
            onChange('');
            return;
        }

        const date = dateVal ? format(new Date(dateVal as Date), 'yyyy-MM-dd') : null;
        onChange(date);
    };

    const handleClear = () => onChange(null);

    const onClose = () => {
        setPickerOpen(false);
    };

    const handleShouldDisableDate = shouldDisableDate ? (date: Date) => (isSameDay(endOfMonth(date), date) ? false : true) : undefined;

    const labelText = renderFormLabel(formattedLabel, '', showRequired);

    return (
        <DatePicker
            ref={divRef}
            value={value === null ? null : new Date(value)}
            label={labelText}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            onChange={handleOnChange}
            format="dd.MM.yyyy"
            onClose={onClose}
            open={pickerOpen}
            shouldDisableDate={handleShouldDisableDate}
            localeText={{
                clearButtonLabel: 'Smazat',
                todayButtonLabel: 'Dnes',
            }}
            slots={{
                inputAdornment: () => (
                    <InputAdornment position="end">
                        <TooltipIcon htmlText={<FormattedMessage id="datepicker.tooltip.smazat" />} aria-label="delete" placement="top-start" showIcon={false}>
                            <Span>
                                <IconButton
                                    onClick={handleClear}
                                    size="small"
                                    sx={{
                                        width: 28,
                                        height: 28,
                                    }}
                                    disabled={disabled}
                                    data-testid={`${name}-calendar-delete-button`}
                                >
                                    <CloseOutlined
                                        color={error ? 'error' : 'action'}
                                        sx={{
                                            color: disabled ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.54)',
                                        }}
                                        fontSize="small"
                                    />
                                </IconButton>
                            </Span>
                        </TooltipIcon>

                        <TooltipIcon
                            htmlText={<FormattedMessage id="datepicker.tooltip.kalendar" />}
                            aria-label="calendar-pick"
                            placement="top-start"
                            showIcon={false}
                        >
                            <Span>
                                <IconButton
                                    onClick={handleOpenPicker}
                                    size="small"
                                    sx={{
                                        width: 28,
                                        height: 28,
                                    }}
                                    disabled={disabled}
                                    data-testid={`${name}-calendar-open-button`}
                                >
                                    <CalendarIcon
                                        color={error ? 'error' : 'action'}
                                        sx={{
                                            color: disabled ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.54)',
                                        }}
                                        fontSize="small"
                                    />
                                </IconButton>
                            </Span>
                        </TooltipIcon>

                        {tooltip}
                    </InputAdornment>
                ),
            }}
            slotProps={{
                popper: {
                    anchorEl,
                },
                actionBar: {
                    actions: ['clear', 'today'],
                },
                textField: {
                    datatype: 'date-picker',
                    sx: {marginTop: 1},
                    className: error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined,
                    variant: 'standard',
                    autoComplete: 'date',
                    label: (
                        <Typography component="span">
                            <Box component="span" sx={{fontWeight: 'bold'}}>
                                {labelText}
                            </Box>
                        </Typography>
                    ),
                    fullWidth: true,
                    error,
                    helperText,
                    FormHelperTextProps: {id: helperTextId, ...FormHelperTextProps},
                    onDoubleClick: disabled ? undefined : handleOpenPicker,
                },
            }}
        />
    );
};
