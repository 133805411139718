import {FormHelperText} from '@mui/material';
import {forwardRef} from 'react';
import NumberFormatCustomProps, {NumberFormatValues, NumericFormat} from 'react-number-format';

type NumberFormatCustomProps = {
    inputRef: (instance: NumberFormatCustomProps | null) => void;
    onChange: (event: {target: {value: string | number | undefined}}) => void;
    error: boolean | undefined;
    helperText: string | undefined;
};

export const NumberFormatCustom = forwardRef((props, ref) => {
    const {onChange, ...other} = props as NumberFormatCustomProps;
    const handleChange = (values: NumberFormatValues) => {
        onChange({
            target: {
                value: values.value,
            },
        });
    };

    return (
        <>
            <NumericFormat
                {...other}
                getInputRef={ref}
                thousandSeparator={' '}
                allowedDecimalSeparators={['.', ',']}
                decimalSeparator={','}
                onValueChange={handleChange}
                valueIsNumericString
            />
            {other?.error && <FormHelperText error>{other?.helperText}</FormHelperText>}
        </>
    );
});
