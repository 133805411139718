import {ApiDate, PppZadatelTyp} from '@eon.cz/apollo13-graphql-web';
import {format, isAfter, isBefore, isFuture, startOfDay, subDays, subYears} from 'date-fns';
import {FormattedMessage} from 'react-intl';
import * as yup from 'yup';
import {Zahranici} from '../types';
import {parseDecimal, validateDate} from './CommonUtils';

// Common setup validations
yup.setLocale({
    mixed: {
        required: () => <FormattedMessage id="yup.required" />,
    },
    string: {
        max: ({max}) => <FormattedMessage id="yup.max" values={{znak: max, pismeno: max === 1 ? '' : max > 1 && max < 5 ? 'y' : 'ů'}} />,
        min: ({min}) => <FormattedMessage id="yup.min" values={{znak: min, pismeno: min === 1 ? '' : min > 1 && min < 5 ? 'y' : 'ů'}} />,
        email: () => <FormattedMessage id="yup.email.format" />,
    },
    array: {
        min: () => <FormattedMessage id="yup.required" />,
    },
});

const string = yup.string();
const boolean = yup.boolean();

// BU validace
const GENVAL015 = string
    .nullable()
    .max(8)
    .matches(/^\d{8}$/, () => <FormattedMessage id="yup.number.ico" />);
const GENVAL051 = string
    .nullable()
    .max(10)
    .matches(/^[0-9]*$/, () => <FormattedMessage id="yup.number.only.cisloPopisne" />);

const obec = string.nullable().required().max(70);
const psc = string.nullable().required().max(10);
const ulice = string.nullable().max(60);
const mistniCast = string.nullable().max(40);
const cisloPopisne = GENVAL051;
const cisloPopisneZahranici = string.nullable().max(10);
const cisloOrientaci = string.nullable().max(10);
const cisloEvidencni = string.nullable().max(10);
const stat = string.nullable().max(60);
const adresa = yup.object().nullable().shape({
    ulice,
    psc,
    obec,
    mistniCast,
    cisloPopisne,
    cisloOrientaci,
    cisloEvidencni,
});
const adresaZahranici = yup.object().nullable().shape({
    ulice: ulice.required(),
    psc: psc.required(),
    obec: obec.required(),
    cisloPopisne: cisloPopisneZahranici.required(),
    stat: stat.required(),
});

// Date validation
const validateDatum = (value: ApiDate, validFuture?: boolean, validPast?: boolean) => {
    const isValidDatum = validateDate(value);
    const isFutureDatum = isFuture(new Date(value));
    const isPastDatum = isBefore(new Date(value), subDays(new Date(), 1));
    const isTo110yearsOld = isAfter(new Date(value), startOfDay(subYears(new Date(), 110)));
    const isMoreThen18yearsOld = isBefore(new Date(value), subYears(new Date(), 18));
    const oldest = format(startOfDay(subYears(new Date(), 110)), 'dd.MM.yyyy');
    const youngest = format(subYears(new Date(), 18), 'dd.MM.yyyy');

    if (!isValidDatum) {
        return {message: <FormattedMessage id="yup.datum.invalid" />};
    }
    if (isFutureDatum && validFuture) {
        return {message: <FormattedMessage id="yup.datum.budoucnost" />};
    }
    if ((!isTo110yearsOld || !isMoreThen18yearsOld) && validFuture) {
        return {message: <FormattedMessage id="yup.datum.rozmezi" values={{oldest, youngest}} />};
    }
    if (isPastDatum && validPast) {
        return {message: <FormattedMessage id="yup.datum.minulost" />};
    }
    return undefined;
};

const datumNotFuture = string
    .test(
        '',
        ({value}) => validateDatum(value, true)?.message,
        (value) => {
            if (value) {
                return !validateDatum(value, true);
            }
            return true;
        },
    )
    .nullable()
    .typeError('Pole je povinné');

const ean = string.required().matches(/^859182400(1|2)\d{8}$/, () => <FormattedMessage id="yup.ean" />);
const jmeno = string
    .required()
    .min(1)
    .max(70)
    .trim(() => <FormattedMessage id="yup.string.whitespace" values={{pole: 'Jméno'}} />)
    .matches(/^[a-zA-Zá-žÁ-Ž ]+$/, () => <FormattedMessage id="yup.string.only" values={{pole: 'Jméno'}} />);

const prijmeni = string
    .required()
    .min(3)
    .max(70)
    .matches(/^\S[a-zA-Zá-žÁ-Ž]*([- ']?)(?:[a-zA-Zá-žÁ-Ž]|\1)*$/, () => <FormattedMessage id="yup.string.only" values={{pole: 'Příjmení'}} />);

const datumNarozeni = datumNotFuture.required();
const ic = GENVAL015.required();
const dic = string
    .nullable()
    .max(20)
    .matches(/^[ C,Z]|CZ|[0-9]+|Skupinove_DPH$/, () => <FormattedMessage id="yup.dic" />);
const nazevFirmy = string.max(120).required();

const email = string
    .email()
    .required()
    .min(6)
    .max(254)
    .matches(
        /^[\w](?!.*([.])\1{1})(?:[a-zA-Z0-9]*[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*[a-zA-Zá-žÁ-Ž0-9]*[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]){0,1}\@[a-zA-Z0-9]+(?:[-a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(?:[-a-zA-Z0-9]+)*){0,5}\.[a-zA-Z0-9]+(?:[-a-zA-Z0-9]+)*[a-zA-Z0-9]$/,
        () => <FormattedMessage id="yup.email.format" />,
    );

const number = string.matches(/^\d+$/, () => <FormattedMessage id="yup.number" />);

const telefonCZ = string.matches(/^(\+420|00420)??[6-7][0-9]{2}?[0-9]{3}?[0-9]{3}$/, () => <FormattedMessage id="yup.phone.cz" />);
const telefonSK = string.matches(/^(\+421|00421)??[9][0-9]{2}?[0-9]{3}?[0-9]{3}$/, () => <FormattedMessage id="yup.phone.sk" />);

const numberZADVAL061 = string
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .matches(/^(?=.*[1-9])\d{1,10}((\.|,)\d{1,3})?$/, () => <FormattedMessage id="yup.number.zadval061" />);

const validateTelefon = (telefon = '') => {
    if (telefon?.length === 0) {
        return string.required();
    }

    if (/00420|\+420/.test(telefon)) {
        return telefonCZ;
    }
    if (/00421|\+421/.test(telefon)) {
        return telefonSK;
    }

    if (/^\d+$/.test(telefon)) {
        return number.test(
            '',
            () => 'Předvolba je povinná',
            () => {
                return false;
            },
        );
    }

    if (!/^\d+$/.test(telefon)) {
        return string.test(
            '',
            () => <FormattedMessage id="yup.phone.not.correct" />,
            () => {
                return false;
            },
        );
    }

    return string.required();
};

export const validationSchemaStepOne = yup.object().shape({
    ean,
    cisloSop: string
        .required()
        .min(8)
        .max(10)
        .test(
            '',
            () => <FormattedMessage id="yup.cisloSop" />,
            (value) => {
                if (value) {
                    return (value.startsWith('12') && value.length === 8) || (value.startsWith('9') && value.length === 10);
                }
                return true;
            },
        ),
    obec: string.required().max(70),
});
export const validationSchemaStepTwo = yup.object().shape({
    zakaznik: yup.object().shape({
        telefon: yup.lazy((value) => validateTelefon(value)),
        email,
        budouciVyrobceShodnySeZadatelem: yup.boolean(),
        budouciVyrobceFO: yup.object().when(['budouciVyrobceShodnySeZadatelem', 'typZadatele'], {
            is: (budouciVyrobceShodnySeZadatelem: boolean, typZadatele: PppZadatelTyp) =>
                budouciVyrobceShodnySeZadatelem === false && typZadatele === PppZadatelTyp.FO,
            then: () =>
                yup.object().shape({
                    jmeno,
                    prijmeni,
                    datumNarozeni,
                    zahranici: string.required(),
                    telefon: yup.lazy((value) => validateTelefon(value)),
                    email,
                    adresa: yup
                        .object()
                        .when('zahranici', {
                            is: (zahranici: Zahranici) => zahranici === Zahranici.NE,
                            then: () => adresa.required(),
                            otherwise: () => adresaZahranici.required(),
                        })
                        .required(),
                }),
        }),
        budouciVyrobcePO: yup.object().when(['budouciVyrobceShodnySeZadatelem', 'typZadatele'], {
            is: (budouciVyrobceShodnySeZadatelem: boolean, typZadatele: PppZadatelTyp) =>
                budouciVyrobceShodnySeZadatelem === false && typZadatele === PppZadatelTyp.PO,
            then: () =>
                yup.object().shape({
                    nazevFirmy,
                    ic,
                    dic,
                    zahranici: string.required(),
                    telefon: yup.lazy((value) => validateTelefon(value)),
                    email,
                    adresa: yup.object().when('zahranici', {
                        is: (zahranici: Zahranici) => zahranici === Zahranici.NE,
                        then: () => adresa.required(),
                        otherwise: () => adresaZahranici.required(),
                    }),
                }),
        }),
        vyrizujici: yup.object().when('vyrizujiciShodnySeZadatelem', {
            is: (vyrizujiciShodnySeZadatelem: boolean) => vyrizujiciShodnySeZadatelem === false,
            then: () =>
                yup.object().when('vyrizujici.typVyrizujici', {
                    is: (typVyrizujici: PppZadatelTyp) => typVyrizujici === PppZadatelTyp.FO,
                    then: () =>
                        yup.object().shape({
                            jmeno,
                            prijmeni,
                            telefon: yup.lazy((value) => validateTelefon(value)),
                            email,
                        }),
                    otherwise: () =>
                        yup.object().shape({
                            nazevFirmy,
                            telefon: yup.lazy((value) => validateTelefon(value)),
                            email,
                        }),
                }),
        }),
    }),
});
export const validationSchemaStepThree = (instalovanyVykonSop: number | undefined, rezervovanyVykonSop: number | undefined | null) =>
    yup.object().shape({
        technickaSpecifikace: yup.object().shape({
            error: yup.boolean().isFalse(),
            zrealizovanyInstalovanyVykon: numberZADVAL061
                // .test(
                //     '',
                //     () => <FormattedMessage id="step.three.technicka.specifikace.warning" />,
                //     (value, ctx) => {
                //         // UTPVAL006 - 10,20
                //         if (
                //             rezervovanyVykonSop &&
                //             value &&
                //             [10, 20].includes(ctx.parent.akumulacniModul?.typVyrobny) &&
                //             parseDecimal(value) !== instalovanyVykonSop &&
                //             parseDecimal(value) * 1.2 < rezervovanyVykonSop
                //         ) {
                //             return false;
                //         }
                //         return true;
                //     },
                // )
                .test(
                    '',
                    () => <FormattedMessage id="yup.zrealizovanyInstalovanyVykon" />,
                    (value) => {
                        // const celkovyVykonStridacuAkumulace = (ctx.parent.akumulacniModul.stridace as PppStridace[])?.reduce(
                        //     (acc, panel) => acc + parseDecimal(panel.vykon ?? 0) * Number(panel.pocet ?? 0),
                        //     0,
                        // );
                        //UTPVAL004 - 100,110
                        // if (
                        //     value &&
                        //     instalovanyVykonSop &&
                        //     [100, 110].includes(ctx.parent.akumulacniModul?.typVyrobny) &&
                        //     celkovyVykonStridacuAkumulace &&
                        //     rezervovanyVykonSop
                        // ) {
                        //     return !(parseDecimal(value) + celkovyVykonStridacuAkumulace > instalovanyVykonSop + 1);
                        // }
                        // UTPVAL002 - 10,20,100,110
                        if (value && rezervovanyVykonSop && instalovanyVykonSop) {
                            return !(parseDecimal(value) > instalovanyVykonSop + 1);
                        }
                        // UTPVAL005 - 100,110
                        // if (
                        //     value &&
                        //     [100, 110].includes(ctx.parent.akumulacniModul?.typVyrobny) &&
                        //     ctx.parent.technickaSpecifikace?.celkovaKapacitaAkumulace &&
                        //     rezervovanyVykonSop
                        // ) {
                        //     return !(
                        //         (parseDecimal(value) + parseDecimal(ctx.parent.technickaSpecifikace?.celkovaKapacitaAkumulace)) * 1.2 <
                        //         rezervovanyVykonSop
                        //     );
                        // }

                        return true;
                    },
                )
                .required(),
            typZdroje: string.required(),
            potvrzeniOVystavbe: boolean.required().oneOf([true], () => <FormattedMessage id="yup.souhlas" />),
            prilohyZRQ14: yup
                .array()
                .min(1)
                .nullable()
                .required(() => <FormattedMessage id="yup.required.file" />),
            sitovaOchranaSoucastiRZ: boolean.required(),

            prilohyZRQ18: yup.array().when('sitovaOchranaSoucastiRZ', {
                is: (val: boolean) => val === false,
                then: () =>
                    yup
                        .array()
                        .min(1)
                        .required(() => <FormattedMessage id="yup.required.file" />),
                otherwise: () => yup.array().nullable(),
            }),
            prilohyRZ: yup
                .array()
                .min(1)
                .nullable()
                .required(() => <FormattedMessage id="yup.required.file" />),
            pocetStridacu: boolean.required().oneOf([true], () => <FormattedMessage id="yup.souhlas" />),
            prilohyZRQ15: yup
                .array()
                .min(1)
                .nullable()
                .required(() => <FormattedMessage id="yup.required.file" />),

            prilohyZRQ16: yup.array().when('zrealizovanyInstalovanyVykon', {
                is: (zrealizovanyInstalovanyVykon: string) => {
                    if (zrealizovanyInstalovanyVykon) {
                        return parseDecimal(zrealizovanyInstalovanyVykon) >= 30;
                    }
                    return false;
                },
                then: () =>
                    yup
                        .array()
                        .min(1)
                        .required(() => <FormattedMessage id="yup.required" />),
                otherwise: () => yup.array().nullable(),
            }),

            prilohyZ1PSCHEMA: yup.array().when('zmenaTechnickychParametruVyrobny', {
                is: (zmenaTechnickychParametruVyrobny: 'ANO' | 'NE') => zmenaTechnickychParametruVyrobny === 'ANO',
                then: () =>
                    yup
                        .array()
                        .min(1)
                        .required(() => <FormattedMessage id="yup.required" />),
                otherwise: () => yup.array().nullable(),
            }),
            prilohyZRQ43: yup.array().when('RZElektromerovehoRozvadeceSoucastiRZ', {
                is: false,
                then: () =>
                    yup
                        .array()
                        .min(1)
                        .required(() => <FormattedMessage id="yup.required" />),
                otherwise: () => yup.array().nullable(),
            }),
            elektromerovyRozvadecJePripraven: boolean.required().oneOf([true], () => <FormattedMessage id="yup.souhlas" />),

            vyrobniModul: yup.object().shape({
                stridace: yup
                    .array()
                    .min(1)
                    .of(
                        yup.object().shape({
                            pocet: string
                                .required()
                                .max(3, () => <FormattedMessage id="yup.number.max" />)
                                .matches(/^[1-9]\d{0,2}$/, () => <FormattedMessage id="yup.number" values={{rozsah: '1 - 999'}} />),
                            nazev: string
                                .required()
                                .min(4)
                                .max(60)
                                .matches(
                                    /^(?!^[^a-zA-Zá-žÁ-Ž0-9])(?!^\d+$)(?!.*([-, .'\/])\1{1})(?:[a-zA-Zá-žÁ-Ž0-9]*[a-zA-Zá-žÁ-Ž0-9-, .'\/]*[a-zA-Zá-žÁ-Ž0-9])?$/,
                                    () => <FormattedMessage id="yup.sitovaOchrana" values={{pole: 'Název střídače'}} />,
                                ),
                            vykon: numberZADVAL061.required(),
                        }),
                    ),
                panely: yup
                    .array()
                    .min(1)
                    .of(
                        yup.object().shape({
                            pocet: string
                                .required()
                                .max(4, () => <FormattedMessage id="yup.number.ZADVAL141" />)
                                .matches(/^[1-9]\d{0,4}$/, () => <FormattedMessage id="yup.number" values={{rozsah: '1 - 9999'}} />),
                            vykon: numberZADVAL061.required(),
                        }),
                    ),
                souhlasSProvedenimZkousekVyrobnihoModulu: boolean.required().oneOf([true], () => <FormattedMessage id="yup.souhlas" />),
                error: yup.boolean().isFalse(),
            }),
            akumulacniModul: yup.object().shape({
                akumulaceVRevizniZprave: boolean.required(),
                celkovaKapacitaAkumulace: string.when('akumulaceVRevizniZprave', {
                    is: true,
                    then: () => numberZADVAL061.required(),
                }),

                stridace: yup.array().when(['typVyrobny', 'akumulaceVRevizniZprave'], {
                    is: (typVyrobny: number, akumulaceVRevizniZprave: boolean) => {
                        return [100, 110].includes(typVyrobny) && akumulaceVRevizniZprave;
                    },
                    then: () =>
                        yup
                            .array()
                            .min(1)
                            .of(
                                yup.object().shape({
                                    pocet: string
                                        .required()
                                        .max(3, () => <FormattedMessage id="yup.number.max" />)
                                        .matches(/^[1-9]\d{0,2}$/, () => <FormattedMessage id="yup.number" values={{rozsah: '1 - 999'}} />),
                                    nazev: string
                                        .required()
                                        .min(4)
                                        .max(60)
                                        .matches(
                                            /^(?!^[^a-zA-Zá-žÁ-Ž0-9])(?!^\d+$)(?!.*([-, .'\/])\1{1})(?:[a-zA-Zá-žÁ-Ž0-9]*[a-zA-Zá-žÁ-Ž0-9-, .'\/]*[a-zA-Zá-žÁ-Ž0-9])?$/,
                                            () => <FormattedMessage id="yup.sitovaOchrana" values={{pole: 'Název střídače'}} />,
                                        ),
                                    vykon: numberZADVAL061.required(),
                                }),
                            ),
                }),
            }),
        }),
    });
